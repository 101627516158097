import Axios from "axios";

let urlParameters = {
  query: "",
  order_by: "date",
  page: 1,
};

const urlGenerator = (newUrl) => {
  newUrl.forEach((item) => {
    const { type, value } = item;
    urlParameters = {
      ...urlParameters,
      [type]: value,
    };
  });

  let URL =
    `${process.env.VUE_APP_PHOTOS_URL}/search?` +
    `&page=${urlParameters.page}` +
    `&query=${urlParameters.query}` +
    `&order_by=${urlParameters.order_by}`;
  return URL;
};

export default {
  async fetchPopular({ commit }) {
    commit("setPhotosTimeline", []);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "order_by", value: "favorites" },
            { type: "page", value: 1 },
          ]),
          {
            headers: {
              "X-FIREBASE-APPCHECK": process.env.VUE_APP_X_FIREBASE_APPCHECK,
            },
          }
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.results);
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchLatest({ commit }) {
    commit("setPhotosTimeline", []);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "order_by", value: "date" },
            { type: "page", value: 1 },
          ]),
          {
            headers: {
              "X-FIREBASE-APPCHECK": process.env.VUE_APP_X_FIREBASE_APPCHECK,
            },
          }
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.results);
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },
  async search({ commit }, payload) {
    commit("setPhotosTimeline", []);
    commit("setSearchText", payload);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "query", value: encodeURIComponent(payload) },
            { type: "page", value: 1 },
          ]),
          {
            headers: {
              "X-FIREBASE-APPCHECK": process.env.VUE_APP_X_FIREBASE_APPCHECK,
            },
          }
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.results);
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },
  async filter({ commit }, payload) {
    commit("setPhotosTimeline", []);
    try {
      await Promise.all([
        Axios.get(urlGenerator(payload), {
          headers: {
            "X-FIREBASE-APPCHECK": process.env.VUE_APP_X_FIREBASE_APPCHECK,
          },
        }).then(({ data }) => {
          if (data) {
            if (data.results.length > 0) {
              commit("setPhotosTimeline", data.results);
            } else commit("setPhotosTimeline", null);
          }
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchMore({ commit }) {
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([{ type: "page", value: urlParameters.page + 1 }]),
          {
            headers: {
              "X-FIREBASE-APPCHECK": process.env.VUE_APP_X_FIREBASE_APPCHECK,
            },
          }
        ).then(({ data }) => {
          if (data.results) {
            if (data.results.length > 0) {
              commit("morePhotosTimeline", data.results);
            }
          }
        }),
      ]);
    } catch (error) {
      commit("setFetchStatus", false);
      console.log(error);
    }
  },
  destroySearching({ commit, dispatch }) {
    commit("setSearchText", null);
    dispatch("filter", [{ type: "query", value: "" }]);
  },
  resetCategory() {
    urlParameters.category = null;
  },
  setdarkmode({ commit }, payload) {
    commit("darkmode", payload);
  },
};
