import Vue from "vue";
import Vuex from "vuex";
import getters from "./app/getters";
import mutations from "./app/mutations";
import actions from "./app/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signUpForm: {
      username: "",
      password: "",
    },
    albums: [],
    photosTimeline: [],
    searchText: "",
    filterSection: false,
    backdrop: false,
    fetchStatus: true,
    darkmode: false,
    categories: [
      "darth vader",
      "obi-wan kenobi",
      "anakin skywalker",
      "r2-d2",
      "yoda",
      "luke skywalker",
      "princess leia",
      "ahsoka tano",
      "han solo",
      "qui-gon jinn",
      "revan",
      "palpatine",
      "din djarin",
      "thrawn",
      "chewbacca",
      "mace windu",
      "darth maul",
      "lando calrissian",
      "grand moff tarkin",
      "jango fett",
      "boba fett",
      "count dooku",
      "plo koon",
      "padme amidala",
      "mara jade",
      "general grievous",
      "kylo ren",
      "rey",
      "captain phasma",
      "c-3po",
    ],
  },
  getters,
  mutations,
  actions,
  modules: {
    // const moduleA = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... },
    //   getters: { ... }
    // }
    // const moduleB = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... }
    // }
    // const store = createStore({
    //   modules: {
    //     a: moduleA,
    //     b: moduleB
    //   }
    // })
    // store.state.a // -> `moduleA`'s state
    // store.state.b // -> `moduleB`'s state
  },
});
